import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Button } from "@material-ui/core";
import { Grid } from '@material-ui/core';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useStyles } from "./styles";
import _ from "lodash";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import apiClient from "../../auth/apiClient";
import clsx from "clsx";
import ShiftSessionStartingAmount from "../../components/ShiftSession/ShiftSessionStartingAmount";
import CreditCardTerminalSelection from "../../components/ShiftSession/CreditCardTerminalSelection";
import CashieredDeviceSelection from "../../components/ShiftSession/CashieredDeviceSelection"
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { useHistory, useParams } from "react-router-dom";
import {
  createCashierShift,
  setCCTerminal,
  setCashieredDevice,
  fetchCashierShift,
  clearTransaction
} from "../../state/slices/shiftSession/shiftSession";
import useHasPermissions from "../../hooks/useHasPermissions";
import CashierShiftsService from "../../services/CashierShiftsService";
import DeviceService from "../../services/DeviceService";
import { useFlags } from "launchdarkly-react-client-sdk";
import CashierService from "../../services/CashierService";
import { CASHIER_DEVICE } from "../../constants";
import { ENTITY_TYPE } from "../../constants";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";
import {selectContextEntity} from "../../state/slices/CoreEntity";

const ShiftSessionPage = () => {
  const classes = useStyles();
  const { showDialog } = useConfirmationDialog();
  const { hasPermissions } = useHasPermissions();
  const history = useHistory();
  const enqueueSnackbar = useEnqueueSnackbar();
  const useCoreEntitySlice = useCoreEntityContext();

  const shiftSession = useSelector((state) => state.shiftSession);
  const currentUserId = useSelector((state) => state.user.UserID);
  const facilityID = useSelector((state) =>useCoreEntitySlice ? state.coreEntities?.ContextID : state.entities?.ContextID);
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const hasRoamingCredentialPermission = hasPermissions(["cashiermodes.roamingcashier"]);
  const hasPayOnEntryCashierPermission = hasPermissions(["cashiermodes.payonentrycashier"]);
  const hasValetHandheldCashierPermission = hasPermissions(["cashiermodes.valethandheldcashier"]);
  const [currentShifts, setCurrentShifts] = useState([]);
  const [selectedCashieredDevice, setSelectedCashieredDevice] = useState("-1");
  const [availableCreditCardTerminals, setAvailableCreditCardTerminals] = useState([{ name: "Cash Only", value: -1 }]);
  const [selectedCreditCardTerminal, setSelectedCreditCardTerminal] = useState(shiftSession?.ccterminalId ?? -1);
  const cashierShiftsService = new CashierShiftsService(apiClient);
  const deviceService = new DeviceService(apiClient);
  const cashierService = new CashierService(apiClient);
  const STEPS = useMemo(() => ["Select Cashiered Device", "Select CC terminal", "Select Starting Amount"], []);
  const entityType = useSelector((state) => useCoreEntitySlice ? selectContextEntity(state)?.typeid: state.entities?.Context?.typeid);
  let isValetAreaEntity = entityType === ENTITY_TYPE.ValetArea;
  const GetInitialCashierDevices = () => {
    const deviceList = [];
    if (hasRoamingCredentialPermission) {
      deviceList.push({ name: "Roaming Cashier <default>", deviceID: "-1" });
    }
    if (isValetAreaEntity && hasValetHandheldCashierPermission) {
        deviceList.push({
          name: "Valet Handheld Cashier",
          deviceID: CASHIER_DEVICE.ValetHandheldCashier,
        });
      }
    return deviceList;
  }

  const [availableCashieredDevices, setAvailableCashieredDevices] = useState(GetInitialCashierDevices);
  const dispatch = useDispatch();
  const { stepName } = useParams();

  const [stepperIndexMap, setStepper] = useState({
    cashieredDevice: 0,
    ccTerminal: 1,
    startingAmount: 2
  });

  const goToStep = stepRoute => {
    history.push(`/shiftsession/${stepRoute}`);
  }

  const resetToStart = () => {
    goToStep("cashieredDevice");
  }

  useEffect(() => {
    //on fresh page load, go to clean route
    switch (stepName) {
      case "cashieredDevice":
        break;
      case "ccTerminal":
        if (!shiftSession?.cashieredDeviceID) resetToStart();
        break;
      case "startingAmount":
        if (!shiftSession?.cashieredDeviceID && !shiftSession?.ccterminalId) resetToStart();
        break;
      default:
        resetToStart();
    }
  }, []);

  useEffect(() => {
    dispatch(fetchCashierShift(currentUserId));
    if (!stepName) {
      resetToStart();
    }
  }, [stepName])

  useEffect(() => {
    if (shiftSession?.shiftID > 0) {
      history.push(`/cashieredDevice`);
    }
  }, [shiftSession]);

  const { cashieredDevices } = useFlags();

  const getActiveStepIndex = () => stepperIndexMap[stepName];

  const fetchCurrentShifts = useCallback(
    async (facilityID) => {
      cashierShiftsService.getCashierShifts(facilityID)
        .then((result) => {
          setCurrentShifts(result?.data);
        })
        .catch(() => {
          enqueueSnackbar("Unable to retrieve cashier shifts for this entity", {
            variant: "error",
            tag: "FailedToFindCashierShiftsForEntity",
          });
        });
    },
    [cashierShiftsService, facilityID, enqueueSnackbar]
  );

  const fetchAvailableDevices = useCallback(
    async (facilityID) => {
      if (!hasPayOnEntryCashierPermission) return;
      let validDevices = GetInitialCashierDevices();
      try {
        const availableDevices = await deviceService.getAvailableCashieredDevices(facilityID);
        if (availableDevices.status === 200) validDevices.push(...availableDevices.data);
      } catch (err) {
        console.error(err); // No need to display failure to user for this dropdown
      }
      setAvailableCashieredDevices(validDevices);
    }, [deviceService.getAvailableCashieredDevices, currentShifts, facilityID]
  );

  const fetchAvailableTerminals = useCallback(
    async (entityid) => {
      let validTerminals = [{ name: "Cash Only", value: -1 }];

      deviceService
        .getAvailableCreditCardTerminals(entityid)
        .then((result) => {
          if (result !== null) {
            var filteredTerminals = currentShifts == null || currentShifts?.length === 0 ?
              result?.data?.collection :
              [...result?.data?.collection]
                .filter(x => currentShifts?.findIndex(y => y.roamingCCTerminalID === x.roamingCCTerminalID) === -1);

            var ccTerminals = filteredTerminals?.map(element => { return { name: element.displayName, value: element.roamingCCTerminalID } });
            setAvailableCreditCardTerminals([...validTerminals, ...ccTerminals]);
          } else {
            setAvailableCreditCardTerminals(validTerminals);
            enqueueSnackbar("No credit card terminals exist for this entity", {
              variant: "error",
              tag: "NoCCTerminalsExistForThisEntity",
            });
          }
        })
        .catch(() => {
          enqueueSnackbar("Unable to retrieve credit card terminals for this entity", {
            variant: "error",
            tag: "FailedToFindCCTerminalsForEntity",
          });
        });
    },
    [deviceService.getAvailableCreditCardTerminals, currentShifts]
  );

  useEffect(() => {
    fetchAvailableDevices(facilityID);
    fetchAvailableTerminals(scopeAwareFacilityID);
  }, [currentShifts, facilityID, scopeAwareFacilityID]);

  useEffect(() => {
    if (!_.isUndefined(facilityID)) fetchCurrentShifts(facilityID)
  }, [facilityID]);

  const validationSchema = [
    Yup.object().shape({
      cashiereddevice: Yup.string().oneOf(
        availableCashieredDevices?.map((device) => {
          return device.deviceID;
        })
      ).required("Required")
    }),
    Yup.object().shape({
      creditcardterminal: Yup.number().oneOf(
        availableCreditCardTerminals?.map((terminal) => {
          return terminal.value;
        })
      ).required("Required")
    }),
    Yup.object().shape({
      startingamount: Yup.number()
        .typeError("Must be a number")
        .min(0, "Must be positive")
        .required("Required"),
    })
  ];

  const currentValidationSchema = validationSchema[getActiveStepIndex()];

  const handleBack = () => {
    history.goBack();
  };

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    mode: "onChange",
    defaultValues: {
      cashiereddevice: selectedCashieredDevice,
      creditcardterminal: selectedCreditCardTerminal,
      startingamount: shiftSession?.startCash ?? "0"
    },
  });

  const {
    control,
    getValues,
    trigger,
    reset,
    formState: { isValid, errors },
  } = methods;

  async function createShift() {
    let newShift = {
      userID: currentUserId,
      deviceID: selectedCashieredDevice < 0 ? null : selectedCashieredDevice,
      entityid: facilityID,
      roamingCCTerminalID: selectedCreditCardTerminal,
      startCash: parseFloat(getValues("startingamount")).toFixed(2),
    };

    if (selectedCashieredDevice === CASHIER_DEVICE.RoamingCashierdefault) {
      newShift.cashierDeviceTypeID = 1;
    } else if (selectedCashieredDevice === CASHIER_DEVICE.ValetHandheldCashier) {
      newShift.cashierDeviceTypeID = 2;
    } else {
      newShift.cashierDeviceTypeID = 3;
    }

    try {
      let currentAvailableDevices;
      let deviceInUse = false;

      if (selectedCashieredDevice !== "-1" && selectedCashieredDevice !== "-2") {
        // double-check device is not in use
        currentAvailableDevices = await deviceService.getAvailableCashieredDevices(facilityID);

        if (currentAvailableDevices.status === 200 && currentAvailableDevices.data &&
          !currentAvailableDevices.data?.some(x => x.deviceID == selectedCashieredDevice)) {
          deviceInUse = true;
        }
      }

      if (deviceInUse) {
        let devicedUsed = availableCashieredDevices.find(x => x.deviceID == selectedCashieredDevice);
        let msg = (<div>A user has already started their shift on <b>{devicedUsed?.name}</b></div>);
        await showDialog({
          title: "Device In Use",
          message: msg,
          buttons: [
            { name: "OK", color: "primary" },
          ],
          paperProps: {
            style: {
              width: "80%"
            }
          }
        });

        // reset available devices and form
        let initialDevices = GetInitialCashierDevices();
        setAvailableCashieredDevices([...initialDevices.concat(currentAvailableDevices.data)]);
        reset(); // reset form
        resetToStart(); // redirect to cashiered device selection
      } else {
        // all good - start shift
        // AO-18082 - Cancel the last cashier session if last session exists 
        const response = await cashierService.getTransactionStatus(currentUserId);
        if (response.status !== 204) {
          await cashierService.cancelTransaction({ cashierID: currentUserId });
          dispatch(clearTransaction());
        }
        const result = await cashierShiftsService.createCashierShift(newShift);
        dispatch(createCashierShift(result.data));
        history.push(`/cashiereddevice`);
      }
    } catch (ex) {
      enqueueSnackbar("Failed to create shift", {
        variant: "error",
        tag: "FailedToCreateCashierShift"
      });
    }
  }

  const handleNext = async (e) => {
    e.preventDefault();
    await trigger();

    switch (stepName) {
      case "cashieredDevice":
        setSelectedCashieredDevice(getValues("cashiereddevice"));
        dispatch(setCashieredDevice(getValues("cashiereddevice")));
        goToStep("ccTerminal");
        break;
      case "ccTerminal":
        setSelectedCreditCardTerminal(getValues("creditcardterminal"));
        dispatch(setCCTerminal(getValues("creditcardterminal")));
        goToStep("startingAmount");
        break;
      case "startingAmount":
        {
          // last step
          let response = await showDialog({
            title: "Start Shift",
            message: "Ready to Start Shift?",
            buttons: [
              { name: "Yes", color: "primary" },
              { name: "No", color: "secondary" },
            ],
            paperProps: {
              style: {
                width: "80%"
              }
            }
          });
          if (response === "No") return;

          createShift();
        }
        break;
    }
  };

  function getStepContent() {
    switch (stepName) {
      case "cashieredDevice":
        return <CashieredDeviceSelection
          availableCashieredDevices={availableCashieredDevices}
          currentSelectedDevice={selectedCashieredDevice}
          control={control}
          errors={errors}
        />
      case "ccTerminal":
        return <CreditCardTerminalSelection
          availableCreditCardTerminals={availableCreditCardTerminals}
          currentSelectedTerminal={selectedCreditCardTerminal}
          control={control}
          errors={errors}
        />
      case "startingAmount":
        return <ShiftSessionStartingAmount
          control={control}
          errors={errors}
        />
      default:
        return "Redirecting to your shift....";
    }
  }

  return (
    <>
      {cashieredDevices && (
        <div className={classes.root}>
          <Stepper activeStep={getActiveStepIndex()} className={clsx(classes.stepper)}>
            {STEPS.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step className={clsx(classes.stepper)} key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <form>
            <div>
              <div className={clsx(stepName)}>
                <Typography className={classes.instructions}>
                  {getStepContent()}
                </Typography>
                <div>
                  <Grid container item xs={12} lg={6} className={clsx(classes.buttonGrid)}>
                    <Grid item xs={12}>
                      <div className={clsx(classes.buttonDiv)}>
                        <Button
                          disabled={stepName === "cashieredDevice"}
                          data-testid="backButton"
                          onClick={handleBack}
                          className={classes.backButton}
                          color="secondary"
                          variant="contained"
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          data-testid={stepName === "startingAmount" ? "startShiftButton" : "nextButton"}
                          onClick={handleNext}
                          className={clsx("next", classes.nextButton)}
                          disabled={isValid === false}
                        >
                          {stepName === "startingAmount" ? "Start Shift" : "Next"}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ShiftSessionPage;