import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import AdminForm from "../../../components/Administration/Groups/AdminForm/index"
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";
import {selectAllEntities} from "../../../state/slices/CoreEntity";

const AdminUsersPage = (props) => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const useCoreEntitySlice = useCoreEntityContext();

  const isAdmin = useSelector((state) => state?.user?.isAdmin);
  const adminEntityID = useSelector((state) =>useCoreEntitySlice ? selectAllEntities(state)?.find(x => x.name.toLowerCase() == "ami")?.entityid : state?.entities?.EntityListRaw.find(x => x.name.toLowerCase() == "ami")?.entityid);

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  console.log(useSelector((state) => state))
  if (!isAdmin) return (<></>)
  return (<AdminForm entityID={facilityID} adminEntityID={adminEntityID} />)
};

export default AdminUsersPage;
