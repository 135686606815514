import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Divider,
  Paper,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  setEntitiesProperty,
  FindEntity
} from "../../../state/slices/entities";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useCancellationToken from "../../../hooks/useCancellationToken";
import apiClient from "../../../auth/apiClient";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";
import {
  selectAllEntities,
  selectEntityById,
  setEntitiesProperty as coreSetEntitiesProperty
} from "../../../state/slices/CoreEntity";

import clsx from "clsx";
import _ from "lodash";
import { useStyles } from "./DeviceVersions.style";

const DeviceVersions = ({ entityID, ...props }) => {
  const classes = useStyles();
  const isAdmin = useSelector((state) => state?.user?.isAdmin);
  const enqueueSnackbar = useEnqueueSnackbar();
  const dispatch = useDispatch();
  const useCoreEntitySlice = useCoreEntityContext();

  const [enhancedSelected, setEnhancedSelected] = useState(null);

  const {
    execute: getVersions,
    cancel: cancelQuery,
    inProgress,
  } = useCancellationToken({
    func: async function({ cancelToken }) {
      const response = await apiClient.get(`/device/versions`, {
        cancelToken: cancelToken,
      });
      dispatch(
        useCoreEntitySlice ?
          coreSetEntitiesProperty({
            entities: response.data.Devices.map((d) => {
              return {
                entityid: d.DeviceId,
                property: "state",
                value: d.Connected ? d.Dsd : null,
              };
            }),
          })
          :
          setEntitiesProperty({
            entities: response.data.Devices.map((d) => {
              return {
                entityid: d.DeviceId,
                property: "state",
                value: d.Connected ? d.Dsd : null,
              };
            }),
          })
      );
    },
    errHandleFunc: (err) => {
      enqueueSnackbar("Failed to load device versions" + err, {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    getVersions();

    return () => {
      cancelQuery();
    };
  }, []);

  // will need to evaluate this and how needed it is and if I am going to supplement this with something else
  const DevicePack = useSelector((state) => {
    let _d = [];
    let _x = _.map(
      (useCoreEntitySlice ? selectAllEntities(state) : state.entities.EntityIDsRaw).filter(
        (e) =>{
          return  (useCoreEntitySlice ? e.typeid : e.entitytype) === 3 &&
              (useCoreEntitySlice ? selectEntityById(state, e.entityid) : FindEntity(state.entities.EntityList, e.entityid)) != null
        }
      ),
      "entityid"
    );
    _x.map((e) => {
      let _z = useCoreEntitySlice ? selectEntityById(state, e) : FindEntity(state.entities.EntityList, e);
      _d.push({
        //walk through basic array, finding rich, adding scoped props to new array
        entityid: _z.entityid,
        parent: useCoreEntitySlice ? selectEntityById(state, _z.parententityid).name : FindEntity(state.entities.EntityList, _z.parententityid).name,
        name: _z.name,
        online: _z.state ? true : false,
        version: _z?.state?.Version ?? "Offline",
        type: _z?.settings?.find((x) => x.name == "devicemode").value,
        gate:
          _z.state === null
            ? null
            : _z?.settings?.find((x) => x.name == "devicemode").value ===
                "Entry" ||
              _z?.settings?.find((x) => x.name == "devicemode").value === "Exit"
            ? _z?.state?.Peripherals?.Gate?.Open === true
              ? "open"
              : "closed"
            : null,
        open:
          _z.state === null
            ? null
            : (_z?.settings?.find((x) => x.name == "laneclosedsign")).value ===
              "true"
            ? false
            : true ?? false, //t/f on setting, if null, false
      });
    });

    //new versioning
    let _q = _.groupBy(_d, "type");
    _.forEach(_q, function(value, key) {
      _q[key] = _.groupBy(_q[key], function(item) {
        return item.version;
      });
    });

    //now return the devices
    //console.log("final", {d:_d,q:_q});
    return {
      devices: _d,
      enhanced: _q,
      types: _.map(_.keys(_q), function(a) {
        return { name: a, frag: _.without(_.keys(_q[a]), "Offline").length };
      }),
    };
  });
  //console.log("completed",DevicePack);

  useEffect(() => {
    return () => {};
  }, [entityID, DevicePack]);

  if (!isAdmin) return <></>;
  if (inProgress)
    return (
      <div className={classes.root}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs>
            <Typography className={classes.title} variant="h4">
              Device Versions
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.title} variant="h6" align="right">
              Portal Version: {process.env.PORTAL_VERSION ?? "Unknown"}
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.titleDivider} />
        <Grid container>
          <Skeleton
            className={classes.skeleton}
            variant="rect"
            width="50vw"
            height="2rem"
            animation="wave"
          />
          <Skeleton
            className={classes.skeleton}
            variant="rect"
            width="50vw"
            height="2rem"
            animation="wave"
          />
          <Skeleton
            className={classes.skeleton}
            variant="rect"
            width="50vw"
            height="2rem"
            animation="wave"
          />
        </Grid>
      </div>
    );
  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs>
          <Typography className={classes.title} variant="h4">
            Device Versions
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.title} variant="h6" align="right">
            Portal Version: {process.env.PORTAL_VERSION ?? "Unknown"}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.titleDivider} />
      <Grid container>
        <Grid item xs={9} className={classes.gridListArea}>
          {!enhancedSelected ? (
            <>
              {_.keys(DevicePack.enhanced).map((e, i) => (
                <Card key={i} className={classes.containerCard}>
                  <CardHeader title={e}></CardHeader>
                  <CardContent>
                    {_.keys(DevicePack.enhanced[e]).map((e2, i2) => (
                      <Card
                        key={i2}
                        className={classes.innerCard}
                        onClick={() => {
                          setEnhancedSelected(DevicePack.enhanced[e][e2]);
                        }}
                      >
                        <CardHeader
                          className={classes.versionCardTitle}
                          title={e2}
                        ></CardHeader>
                        <CardContent className={classes.cardContent}>
                          {DevicePack.enhanced[e][e2].length}
                        </CardContent>
                      </Card>
                    ))}
                  </CardContent>
                </Card>
              ))}
            </>
          ) : (
            <>
              <Card>
                <CardHeader
                  className={classes.detailTitle}
                  title={
                    enhancedSelected[0].type +
                    " - " +
                    enhancedSelected[0].version
                  }
                  onClick={() => {
                    setEnhancedSelected(null);
                  }}
                ></CardHeader>
                <CardContent>
                  <div className={classes.deviceList}>
                    {enhancedSelected.map((e, i) => (
                      <Paper
                        className={clsx([
                          e.online ? "online" : "",
                          classes.chip,
                        ])}
                        key={i}
                        title={e.parent}
                        alt={e.parent}
                      >
                        <div className={classes.chipTitle}>{e.parent}</div>
                        {e.name}
                      </Paper>
                    ))}
                  </div>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button
                    onClick={() => {
                      setEnhancedSelected(null);
                    }}
                  >
                    BACK
                  </Button>
                </CardActions>
              </Card>
            </>
          )}
        </Grid>
        <Grid item xs={3} className={classes.gridOverviewArea}>
          <Paper style={{ padding: 8, marginBottom: 15 }}>
            <div>
              <span className={clsx(["total", classes.overviewTitle])}>
                TOTAL
              </span>
              <span className={classes.overviewValue}>
                {DevicePack.devices.length}
              </span>
            </div>
          </Paper>
          <Paper style={{ padding: 8, marginBottom: 15 }}>
            <div>
              <span className={clsx(["online", classes.overviewTitle])}>
                ONLINE
              </span>
              <span className={classes.overviewValue}>
                {
                  DevicePack.devices.filter((device) => device.online === true)
                    .length
                }
              </span>
            </div>

            <div>
              <span className={clsx(["gatesup", classes.overviewTitle])}>
                GATES UP
              </span>
              <span className={classes.overviewValue}>
                {
                  DevicePack.devices.filter((device) => device.gate === "open")
                    .length
                }
              </span>
            </div>

            <div>
              <span className={clsx(["offline", classes.overviewTitle])}>
                OFFLINE
              </span>
              <span className={classes.overviewValue}>
                {
                  DevicePack.devices.filter((device) => device.online === false)
                    .length
                }
              </span>
            </div>
          </Paper>

          {/* fragments here  make it a subcomp and pass in the `fragments` object*/}
          <Paper style={{ padding: 8, marginBottom: 15 }}>
            <div>
              <span className={clsx(["total", classes.overviewTitle])}>
                FRAGMENTATION
              </span>
            </div>
            {DevicePack.types.map((_type, i) => (
              <div key={i}>
                <span
                  title={`There are ${_type.frag} different versions of ${_type.name}`}
                  className={clsx([_type.name, classes.overviewSubTitle])}
                >
                  {_type.name.toUpperCase()}
                </span>
                <span className={classes.overviewSubValue}>
                  {_type.frag === 0 ? (
                    <span className={clsx(["offline"])}>{"N/A"}</span>
                  ) : (
                    <span className={clsx([`frag_${_type.frag}`])}>
                      {_type.frag}
                    </span>
                  )}
                </span>
              </div>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeviceVersions;
