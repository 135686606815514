import React, { useEffect } from "react";
import {shallowEqual, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Activity from "../../components/Activity";
import { useStyles } from "./styles";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";

import { FindEntity } from "../../state/slices/entities";
import AlertList from "../../components/Alert/ListView/AlertList";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import useHasPermissions from "../../hooks/useHasPermissions";
import OccupancyDashboardWidget from "../../components/Occupancy/DashboardWidget/DashboardWidget.index";
import RevenueDashboardWidget from "../../components/Dashboard/Revenue/DashboardWidget/DashboardWidget.index";
import RevenueDataProvider from "../../components/Dashboard/Revenue/DashboardWidget/RevenueDataProvider";
import OccupancyDataProvider from "../../components/Occupancy/DashboardWidget/OccupancyDataProvider";
import { RevenuePollingFlagProvider } from "../../components/Dashboard/Revenue/DashboardWidget/useRevenueDashboardPollingContext";
import AccessGroupProvider from "../../providers/AccessGroupsProvider";
import {selectContextEntity} from "../../state/slices/CoreEntity";
import moment from "moment/moment";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";

const AnalyticsPage = (props) => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
    const useCoreEntitySlice = useCoreEntityContext();

    const timezone = useSelector((state) => {
        const tz = useCoreEntitySlice ? selectContextEntity(state).details?.timezone : FindEntity(state.entities?.EntityList ?? [], facilityID)?.details
            ?.timezone;
        return tz || moment.tz.guess();
    }, shallowEqual);

  const { hasPermissions } = useHasPermissions();
  const hasAlertsViewPerm = hasPermissions(["dashboard.alerts.view"]);
  const hasOccupancyViewPerm = hasPermissions(["dashboard.occupancy.view"]);
  const hasActivityViewPerm = hasPermissions(["dashboard.activity.view"]);
  const hasRevenueViewPerm = hasPermissions(["dashboard.revenue.view"]);

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: "lg" });
  }, [layoutDispatch]);

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={12}>
        {hasAlertsViewPerm && <AlertList />}
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3}>
          {hasActivityViewPerm && (
            <AccessGroupProvider>
                <Activity entityId={facilityID} accordion={true} tz={timezone} supportMultiEntities={true}/>
            </AccessGroupProvider>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {hasOccupancyViewPerm && (
          <OccupancyDataProvider>
            <OccupancyDashboardWidget />
          </OccupancyDataProvider>
        )}
      </Grid>
      <Grid item xs={12}>
        {hasRevenueViewPerm && (
            <RevenueDataProvider>
                <RevenuePollingFlagProvider>
                  <RevenueDashboardWidget />
                </RevenuePollingFlagProvider>
            </RevenueDataProvider>
        
        )}
      </Grid>
    </Grid>
  );
};

export default AnalyticsPage;
